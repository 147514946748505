<template>
  <div v-if="job" class="flex-grow-1">
    <v-row>
      <v-col class="profile-details">
        <preloader v-if="!job && applicantStatus.getting" />
        <div v-if="job" class="d-flex flex-column">
          <div class="d-flex align-center mt-4 justify-space-between">
            <div class="d-flex align-center">
              <v-btn
                @click="$router.go(-1)"
                class="rounded mr-5"
                color="grey lighten-2"
                outlined
                x-small
                fab
              >
                <v-icon size="25" color="dark">mdi-chevron-left</v-icon>
              </v-btn>

              <div class="d-flex flex-row gap-7">
                <h2 class="text-h4 font-weight-black text-capitalize">
                  List Of Job Applicants
                </h2>
              </div>
            </div>
            <div class="d-flex align-center mt-3">
              <p>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on">
                      <span class="success--text">
                        <v-chip
                          :class="job.status == 'Open' ? 'primary' : 'default'"
                          small
                          label
                        >
                          <v-icon size="14" :class="job.status == 'Open' ? 'white--text' : 'black--text' " class="mr-2">mdi-flag-variant</v-icon>
                          {{ job.status }}
                        </v-chip>
                      </span>
                    </div>
                  </template>
                  <span>Job Status</span>
                </v-tooltip>
              </p>
              <p class="ml-2">
                <v-btn @click="reload()" :loading="applicantStatus.getting" class="transparent shadow-lg" icon>
                  <v-icon>mdi-refresh</v-icon>
                </v-btn>
              </p>
            </div>
          </div>
        </div>
        
        <custom-alert type="info" border="left" text>
          Only one applicant can be hired per job listing.
        </custom-alert>

        <div v-if="job" class="d-flex align-center gap-5 mt-5">
          <v-chip
            v-for="(skill, i) in job.skillSet"
            :key="i"
            small
          >
            {{ skill }}
          </v-chip>
        </div>

        <v-card
          v-if="job"
          class="mt-5 shadow-lg mb-5"
          dense
        >
          <v-card-text class="pa-7">
            <v-simple-table dense>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th>Job Title</th>
                    <th>Contract Length</th>
                    <th>Locale</th>
                    <th>Year Level</th>
                    <th>Contract Dates</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td width="20%">{{ job.title }}</td>
                    <td width="20%">{{ job.contractLength }}</td>
                    <td width="20%">{{ school.locale }}</td>
                    <td width="20%">{{ job.yearLevel }}</td>
                    <td width="20%">
                      <div class="d-flex flex-wrap gap-5 mt-1"> 
                        <v-chip
                          v-for="(range, i) in sortContractDates(job.contractDates)"
                          :key="i"
                          small
                        >{{ range[0] | simpleDate }} - {{ range[1] | simpleDate }}</v-chip>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>

        <div class="d-flex flex-end gap-10 mb-5">
          <v-sheet max-width="200">
            <v-select
              v-model="show"
              :items="['Alphabetical', 'Newest', 'Shortlisted', 'Status']"
              placeholder="Show"
              hide-details
              outlined
              dense
            ></v-select>
          </v-sheet>
          
          <v-sheet width="350">
            <v-combobox
              v-model="skill"
              placeholder="Filter Skill Set"
              :items="job.skillSet"
              hide-details
              clearable
              outlined
              dense
            ></v-combobox>
          </v-sheet>
        </div>

        <v-card class="flex-grow-1 shadow-lg" v-if="applicants">
          <v-simple-table class="flex-grow-1">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Teacher</th>
                  <th>Skill Sets Matched</th>
                  <th>Years Experience</th>
                  <th class="text-center">Preferred</th>
                  <th class="text-center">Shortlisted</th>
                  <th class="text-center">Messages</th>
                  <th class="text-center">Answer</th>
                  <th class="text-left">Status</th>
                  <th class="text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="applicant in applicants" :key="applicant.id">
                  <!-- NAME -->
                  <td>
                    <router-link
                      :to="{ name: 'TalentProfile', params: { id: applicant.userid } }"
                      class="d-flex align-center font-weight-bold heading-font primary--text text-capitalize text-decoration-none underline-on-hover"
                    >
                      <UserPhoto style="width: auto !important; margin-right: 5px;" :size="30" photoSize="thumb" :id="applicant.user.userid" />
                      <span class="ml-1">{{ `${applicant.user.firstName} ${applicant.user.lastName}` }}</span>
                    </router-link>
                  </td>
                  
                  <!-- SKILLSETS MATCHED -->
                  <td>
                    {{ matchedSkillset($store.getters['profile/userProfile'](applicant.user.userid).skillset).join(', ') }}
                  </td>

                  <!-- YEARS EXPERIENCE -->
                  <td>
                    {{ $store.getters['profile/userProfile'](applicant.user.userid).teachingLvl }}
                  </td>
                  
                  <!-- PREFERRED -->
                  <td class="text-center">
                    <FavouriteTeacher :user="$store.getters['users/userData'](applicant.user.userid)" disable/>
                  </td>
                  
                  <!-- SHORTLISTED -->
                  <td class="text-center">
                    <v-icon
                      :color="applicant.shortlisted ? 'yellow darken-2' : 'default'"
                    >
                      {{ applicant.shortlisted ? 'mdi-star' : 'mdi-star-outline' }}
                    </v-icon>
                  </td>
                  

                  <!-- MESSAGES -->
                  <td class="text-center">
                    {{ $store.getters['message/countMessagesByJobId'](job.id) }}
                  </td>
                  
                  <!-- ANSWER -->
                  <td class="text-center">
                    <v-btn
                      @click="viewAnswers(applicant)"
                      color="primary"
                      small
                      icon
                    >
                      <v-icon small>mdi-forum</v-icon>
                    </v-btn>
                  </td>
                  
                  <!-- STATUS -->
                  <td>
                    <v-chip
                      :class="$store.getters['jobs/getClassStatus'](applicant.status)"
                      outlined
                      label
                      small
                    >
                      {{ applicant.status }}
                    </v-chip>
                  </td>

                  <!-- ACTION -->
                  <td class="text-center">
                    <v-menu v-if="job.status == 'Open'" offset-y left>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="accent gradient py-3"
                          v-bind="attrs"
                          v-on="on"
                          class="rounded"
                          depressed
                          x-small
                          fab
                        >
                          <v-icon size="17">mdi-cog-outline</v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item-group>
                          <v-list-item @click="openDialog(applicant)" dense class="mx-auto">
                            <v-list-item-icon class="mr-2">
                              <v-icon size="16" class="light-blue--text">mdi-upload</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>
                              Upload Contract
                            </v-list-item-title>
                          </v-list-item>
                          
                          <v-list-item
                            @click="
                              updateStatus({
                                applicant: applicant,
                                action: 'hire',
                              })
                            "
                            :loading="applicantStatus.getting"
                            dense
                          >
                            <v-list-item-icon class="mr-2">
                              <v-icon size="16" class="success--text">mdi-check</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>
                              Award Job to Applicant
                            </v-list-item-title>
                          </v-list-item>
                          
                          <v-list-item
                            v-if="!applicant.shortlisted"
                            @click="shortList(applicant)"
                            dense
                          >
                            <v-list-item-icon class="mr-2">
                              <v-icon size="16" class="yellow--text">mdi-star</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>
                              Shortlist Applicant
                            </v-list-item-title>
                          </v-list-item>
                          
                          <v-list-item
                            v-if="applicant.shortlisted"
                            @click="removeFromShortList(applicant)"
                            dense
                          >
                            <v-list-item-icon class="mr-2">
                              <v-icon size="16">mdi-star-outline</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>
                              Remove From Shortlist
                            </v-list-item-title>
                          </v-list-item>

                          <v-list-item
                            @click="
                              updateStatus({
                                applicant: applicant,
                                action: 'decline',
                              })
                            "
                            :loading="applicantStatus.getting"
                            dense
                          >
                            <v-list-item-icon class="mr-2">
                              <v-icon size="16" class="warning--text">mdi-cancel</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>
                              Decline Applicant
                            </v-list-item-title>
                          </v-list-item>
                          <v-list-item
                            v-if="!$store.getters['message/countMessagesByJobId'](job.id)"
                            @click="messageApplicant(applicant)"
                            dense
                          >
                            <v-list-item-icon class="mr-2">
                              <v-icon size="16" color="primary">mdi-email-outline</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>
                              Message
                            </v-list-item-title>
                          </v-list-item>
                          <v-list-item
                            v-if="$store.getters['message/countMessagesByJobId'](job.id)"
                            :to="{ name: 'MessageData', params: { id: $store.getters['message/getMessagesByJobId'](job.id).id } }"
                            dense
                          >
                            <v-list-item-icon class="mr-2">
                              <v-icon size="16" color="primary">mdi-email-outline</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>
                              Message Thread
                            </v-list-item-title>
                          </v-list-item>
                        </v-list-item-group>
                      </v-list>
                    </v-menu>

                    <v-tooltip v-if="job.status == jobStatusArray.declined" top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="default py-3"
                          v-bind="attrs"
                          v-on="on"
                          x-small
                        >
                          <v-icon size="17">mdi-cog-outline</v-icon>
                        </v-btn>
                      </template>
                      <span>Job is already closed</span>
                    </v-tooltip>
                  </td>
                </tr>
                <tr v-if="applicantStatus.getting">
                  <td></td>
                  <td></td>
                  <td class="text-center">
                    <preloader
                      class="mx-auto"
                      v-if="applicantStatus.getting"
                    />
                  </td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
    </v-row>

    <template>
      <v-dialog
        v-model="applicantStatus.uploadContractDialog"
        max-width="450"
        persistent
      >
        <v-card>
          <v-card-title class="font-weight-bold">Upload Contract</v-card-title>
          <v-card-text>
            <v-form ref="contractForm">
              <v-progress-linear
                v-if="applicantStatus.uploadProgress"
                :value="applicantStatus.uploadProgress"
                height="4"
                color="primary"
                class="mb-2"
                reactive
              ></v-progress-linear>
              <div><small>Allowed files: .pdf, .doc, .docx, .ppt</small></div>
              <v-file-input
                v-model="contract.contractFile"
                placeholder="Select Contract"
                prepend-icon=""
                prepend-inner-icon="mdi-file-document-outline"
                accept=".pdf, .doc, .docx, .ppt"
                ref="contractInput"
                :rules="[rules.required]"
                outlined
              ></v-file-input>

              <v-btn
                @click="validateForm()"
                :loading="applicantStatus.uploadingContract"
                color="accent white--text"
                class="mr-2 gradient"
                depressed
                >Upload</v-btn
              >

              <v-btn
                @click="closeDialog()"
                :disabled="applicantStatus.uploadingContract"
                depressed
                >Cancel</v-btn
              >
            </v-form>
          </v-card-text>
        </v-card>
      </v-dialog>
    </template>

    <v-dialog
      v-if="job"
      v-model="messageDialog"
      :max-width="$dialog.medium"
      no-click-animation
      persistent
    >
      <v-card>
        <v-btn @click="closeMessageDialog()" class="mr-n2 mt-n2" icon absolute right top>
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <Compose
          ref="compose"
          :toMessage="toMessage"
          :subject="messageSubject"
          :job="jobId" 
          :transition="false"
          @cancel="closeMessageDialog()"
        />
      </v-card>   
    </v-dialog>

     <!-- JOB ANSWER -->
    <GlobalDialog :show="answerDialog" :dialogSize="'content'">
      <template #header>
        <div class="d-flex justify-space-between flex-grow-1">
          <div>
            <span class="font-weight-bold" v-if="selectedApplicantAnswer">{{ selectedApplicantAnswer.user.firstName }}'s Answer </span>
          </div>
          <div 
           v-if="selectedApplicantAnswer"
          class="justify-end">
            <v-chip
              :class="$store.getters['jobs/getClassStatus'](selectedApplicantAnswer.status)"
              outlined
              small
            >
              {{ selectedApplicantAnswer.status }}
            </v-chip>
          </div>
        </div>
      </template>
      <template #body>
        <div v-if="job && selectedApplicantAnswer">
          <div v-if="job.question1">
            <div class="font-weight-black black--text mb-2">Question 1: </div> 
            <p class="body-1 mb-0">{{ job.question1 }}</p>
            <p class="body-1"><span class="font-weight-black">Answer:</span> {{ selectedApplicantAnswer.answers.q1 }}</p>
          </div>
          <div v-if="job.question2">
            <div class="font-weight-black black--text mb-2">Question 2: </div> 
            <p class="body-1 mb-0">{{ job.question2 }}</p>
            <p class="body-1"><span class="font-weight-black">Answer:</span> {{ selectedApplicantAnswer.answers.q2 }}</p>
          </div>
          <div v-if="job.question3">
            <div class="font-weight-black black--text mb-2">Question 3: </div> 
            <p class="body-1 mb-0">{{ job.question3 }}</p>
            <p class="body-1"><span class="font-weight-black">Answer:</span> {{ selectedApplicantAnswer.answers.q3 }}</p>
          </div>
          <div v-if="job.question4">
            <div class="font-weight-black black--text mb-2">Question 4: </div> 
            <p class="body-1 mb-0">{{ job.question4 }}</p>
            <p class="body-1"><span class="font-weight-black">Answer:</span> {{ selectedApplicantAnswer.answers.q4 }}</p>
          </div>
          <div v-if="job.question5">
            <div class="font-weight-black black--text mb-2">Question 5: </div> 
            <p class="body-1 mb-0">{{ job.question5 }}</p>
            <p class="body-1"><span class="font-weight-black">Your answer:</span> {{ selectedApplicantAnswer.answers.q5 }}</p>
          </div>
        </div>
      </template>
      <template #footer>
        

        <v-btn class="accent gradient" @click="closeAnsDialog()" depressed small
          >Close</v-btn
        >
      </template>
    </GlobalDialog>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import _filter from "lodash/filter";
import _uniqBy from "lodash/uniqWith";
import _intersection from "lodash/intersection";
import _orderBy from "lodash/orderBy";
import firebase from "firebase";
import rules from "@/rules";
import "animate.css";
import Compose from '@/views/Message/Compose'
import moment from 'moment'

export default {
  name: "ReviewApplicants",

  metaInfo: {
    title: "Review Applicants",
  },

  data() {
    return {
      rules,
      moment,
      getUrl: {},
      answerDialog: false,
      selectedApplicantAnswer: null,
      messageDialog: false,
      toMessage: null,
      messageSubject: null,
      jobId: null,
      show: 'Newest',
      skill: null,
      labelFont: 'heading-font default-size primary--text font-weight-bold'
    };
  },

  /*------------------------------------------------------------------------------
   * COMPONENTS
   *----------------------------------------------------------------------------*/
  components: {
    Compose
  },

  /* -------------------------------------------------------------------------- */
  /*                                  COMPUTED                                  */
  /* -------------------------------------------------------------------------- */
  computed: {
    ...mapState({
      user: (state) => state.user.user,
      users: (state) => state.users.users,
      job: (state) => state.job.selectedJob,
      jobStatusArray: (state) => state.jobs.jobStatus,
      school: (state) => state.schools.school,
      status: (state) => state.job.status,
      contract: (state) => state.apply.contract,
      applicantStatus: (state) => state.apply.status,
      appliedUserInfo: (state) => state.apply.appliedUserInfo,
    }),

    viewApplicants() {
      let canView = false;
      if (this.user) {
        if (this.user.role == "admin") {
          canView = true;
        } else {
          if (this.job) {
            let currentUser = firebase.auth().currentUser;
            canView = currentUser.uid == this.job.useruid ? true : false;
          }
        }
      }
      return canView;
    },

    applicants() {
      let applications = this.appliedUserInfo

      if (this.show == 'Alphabetical') {
        applications = _orderBy(applications, (applicant) => {
          return applicant.user.firstName
        }, 'asc')
      }
      
      if (this.show == 'Newest') {
        applications = _orderBy(applications, 'createdAt', 'desc')
      }
      
      if (this.show == 'Shortlisted') {
        applications = applications.filter(application => application.shortlisted)
      }
      
      if (this.show == 'Status') {
        applications = _orderBy(applications, 'status', 'asc')
      }

      if (this.skill) {
        applications = applications.filter(applicant => {
          return this.$store.getters['profile/userProfile'](applicant.user.userid).skillset && 
                 (this.$store.getters['profile/userProfile'](applicant.user.userid).skillset).includes(this.skill)
        })
      }

      return _uniqBy(applications, 'userid')
    },

    checkApplied() {
      if (this.job) {
        return _filter(this.jobApplied, ["jobId", this.job.id]);
      } 
      else {
        return false;
      }
    },
  },

  watch: {
    'job': function (job) {
      if (job) {
        this.getAppliedUserInfo(job.id);
        this.$store.dispatch('message/getMessageByJobId', job.id)
      }
    },
  },
  /* -------------------------------------------------------------------------- */
  /*                                   METHODS                                  */
  /* -------------------------------------------------------------------------- */
  methods: {
    ...mapActions("job", [
      "getSelectedJobById", 
      "checkFavorite", 
    ]),
    
    ...mapActions("apply", [
      "updateApplicantStatus",
      'removeFromShortList',
      "getAppliedUserInfo",
      "uploadContract",
      "getJobApplied",
      'shortList',
    ]),

    ...mapActions("users", ["getUserByUid"]),
    ...mapActions("schools", ["getUserSchool"]),
    ...mapActions("favorite", ["getTalentFavorites"]),

    updateStatus(data) {
      if (data.action == "decline") {
        Promise.all([
          this.updateApplicantStatus({
            applicant: data.applicant,
            actions: this.jobStatusArray.declined,
          }),
        ])
        .then(() => {
          this.getAppliedUserInfo(this.job.id)
        });
      } 
      else if (data.action == "hire") {
        Promise.all([
          this.updateApplicantStatus({
            applicant: data.applicant,
            actions: this.jobStatusArray.hired,
          }),
        ])
        .then(() => {
          this.getAppliedUserInfo(this.job.id)
          this.getSelectedJobById({ id: this.$route.params.id });
        });
      }
    },

    openDialog(applicant) {
      this.$store.commit("apply/setUploadContractDialog", true);
      this.$store.commit("apply/setContractApplicant", applicant);
      this.getUserByUid(applicant.userid);
    },

    closeDialog() {
      this.$store.commit("apply/setUploadContractDialog", false);
      this.$refs.contractForm.reset();
    },

    validateForm() {
      if (this.$refs.contractForm.validate()) {
        Promise.all([this.uploadContract()]).then(() => {
          // this.closeDialog()
          this.getAppliedUserInfo(this.job.id);
          this.getSelectedJobById({ id: this.$route.params.id });
        });
      }
    },

    getPDFurl(applicant) {
      var storage = firebase.storage();
      storage
        .ref(`contract/${applicant.contractFile}`)
        .getDownloadURL()
        .then((url) => {
          window.open(url, "_blank");
        })
        .catch((error) => {
          console.log(error.message);
          storage
            .ref(`contract/${applicant.contractFile}`)
            .getDownloadURL()
            .then((url) => {
              window.open(url, "_blank");
            });
        });
    },

    reload() {
      this.getSelectedJobById({ id: this.$route.params.id });
      this.getJobApplied();
      this.getAppliedUserInfo(this.job.id);
    },

    messageApplicant(applicant) {
      this.toMessage = applicant.userid
      this.messageSubject = this.job.title
      this.jobId = this.job.id
      this.messageDialog = true
    },

    closeMessageDialog() {
      this.messageDialog = false
      if (this.$refs.compose) this.$refs.compose.sent = false
      this.$store.commit('message/setMessage', null)
    },

    matchedSkillset(skills) {
      if (this.job) {
        return _intersection(skills, this.job.skillSet)
      }
      else {
        return []
      }
    },

    viewAnswers(applicant) {
      this.answerDialog = true
      this.selectedApplicantAnswer = applicant
    },

    closeAnsDialog() {
      this.answerDialog = false
      this.selectedApplicantAnswer = null
    },

    sortContractDates(contractDates) {
      return _orderBy(contractDates, (date) => {
        return moment(date[0]).valueOf()
      }, 'asc')
    }
  },

  /*------------------------------------------------------------------------------
   * CREATED
   *----------------------------------------------------------------------------*/
  created() {
    this.getTalentFavorites()
    
    if (!this.job) {
      this.getSelectedJobById({ id: this.$route.params.id });
      this.getJobApplied();
    } 
    else {
      if (this.job.id !== this.$route.params.id) {
        this.getSelectedJobById({ id: this.$route.params.id });
        this.getJobApplied();
      }

      this.getAppliedUserInfo(this.job.id);
    }

    this.getUserSchool()
  },
};
</script>


<style scoped>
.w150{
  min-width: 150px !important;
}
.w160{
  min-width: 160px !important;
}
</style>